<template>
  <section class="register">
    <div class="container">
      <div class="row">
        <div class="regiester-wrapper">
          <div class="register-content">
            <div
              class="logo"
              style="display: flex; justify-content: center "
            >
              <b-img
                :src="require('../assets/images/signupLogo.svg')"
                alt="Named color image (red)"
                width="250px"
              />
            </div>
            <h1 style="text-align: center; color: #101828; font-size: 30px; font-weight: 400; margin-bottom: 7px;">
              {{ $t('Welcome back') }}
            </h1>
            <p style="text-align: center; color: #475467; font-size: 16px; font-weight: 400;">
              Welcome back! Please enter your details.
            </p>
            <b-form-group
              id="input-group-1"
              :label="$t('Email')"
              label-for="input-1"
              style="font-size: 16px; width: 100%; color: #344054; font-weight: 500;"
            >
              <b-form-input
                id="input-1"
                v-model="$v.login.email.$model"
                style="height: 50px;"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
                placeholder="Enter your email"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('RequiredField') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="$t('Password')"
              label-for="input-1"
              style="font-size: 16px; margin-top: 40px; color: #344054; font-weight: 500;"
            >
              <div class="input-group mb-3">
                <b-form-input
                  id="input-2"
                  v-model="$v.login.password.$model"
                  style="height: 50px;"
                  placeholder="Enter your password"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control"
                  :state="validateState('password')"
                  aria-describedby="input-1-live-feedback"
                  @keyup.enter="onSubmit"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    style="border-radius:0 5px 5px 0px; height: 50px;"
                    @click="showPassword = !showPassword"
                  >
                    <span><i
                      class="fa"
                      :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                      aria-hidden="true"
                    />
                    </span>
                  </span>
                </div>
              </div>
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('RequiredField') }}</b-form-invalid-feedback>
            </b-form-group>
            <div
              class="error"
              :style="isLogged == false && getIsLoggedIn == false ? 'justify-content: space-between' : 'justify-content: flex-end'"
            >
              <p
                v-if="isLogged == false && getIsLoggedIn == false"
                style="color:red"
                class="test"
              >
                {{ $t('Email_Or_Password_Is_Incorrect') }}
              </p>
              <!-- <p
                v-b-modal.modal-forgot-password
                class="register-content__forgot-password"
                @click="openModal"
              >
                {{ $t('ForgotPassword') }}?
              </p> -->
            </div>
            <div class="buttonsEverywhere">
              <!-- padding: 6px 48%; border-radius:6px -->
              <button
                style="border-radius:6px; width: 100%; "
                type="submit"
                variant="none"
                class="buttonSubmit"
                @click="onSubmit"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                />
                <div v-if="loading == false">
                  {{ $t('Login') }}
                </div>
              </button>
              <!-- <button
                type="submit"
                class="btn btn-primary"
                @click="loadingg(), onSubmit "
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                />Sign In</button> -->
              <!-- <LoadingButton
                :is-loading="isLoading"
                @click="onSubmit"
              /> -->
            </div>
            <p style="text-align: center; margin-top: 3%; font-size: 14px; font-weight: 400;">
              Having Issues? <span style="color: #FF274F; font-weight: 400;">Contact Support</span>
            </p>

          </div>
          <div
            class="register-image"
          />
        </div>
      </div>
    </div>
    <ForgotPasswordModal />
    <ChangeFirstPasswordModal
      :login="login"
      @changeP="changePass"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    >
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, email,
} from 'vuelidate/lib/validators'
import eventBus from '@/main'
import * as signalR from '@microsoft/signalr'
import ForgotPasswordModal from './products/modals/ForgotPasswordModal.vue'
import ChangeFirstPasswordModal from './products/modals/ChangeFirstPasswordModal.vue'
import { api } from '../domainConfig';

const domain = api.stockManagementDomainNotification

// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export default {
  components: {
    ForgotPasswordModal,
    ChangeFirstPasswordModal,
  },

  mixins: [validationMixin],
  data() {
    return {
      showPassword: false,
      show: true,
      isLogged: true,
      loading: false,
      login: {
        email: '',
        password: '',
      },
    }
  },
  validations: {

    login: {
      email: {
        required,
        email,
      },
      // password: {
      //   required, minLength: minLength(8), maxLength: maxLength(30), regex: regex('^(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$'),
      // },
      password: {
        required,
        // passwordRegex(value) {
        //   if (!passwordRegex.test(value)) {
        //     return false;
        //   }
        //   return true;
        // },
      },
    },
  },
  computed: {
    ...mapGetters(['getIsLoggedIn', 'getLoggedInUser']),
  },
  methods: {
    ...mapActions(['attemptLogin', 'changePasswordForUser', 'getUsersPagination']),

    validateState(name) {
      const { $dirty, $error } = this.$v.login[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.login.$touch();
      if (this.$v.login.$anyError) {
        return;
      }

      // eslint-disable-next-line no-new
      // const myPromise = new Promise(() => {
      this.attemptLogin({
        object: this.login,
        successCallback: () => {
          this.isLogged = true
          this.loading = true
          if (this.getLoggedInUser.isFirstLoginExecuted == false) {
            this.$bvModal.show('modal-change-first-password');
          } else {
            this.$router.push('/');
          }
        },
      })
      eventBus.$emit('signup-data', this.login.email);
      localStorage.setItem('signupData', JSON.stringify(this.login.email));
      // })

      // myPromise.then(
      //   () => {
      // console.log('token', this.getLoggedInUser.token)
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${domain}/NotificationUserHub`, {
          accessTokenFactory: () => {
            return this.getLoggedInUser.token;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        connection.start();
        console.log('SignalR Connected.');
      } catch (err) {
        // setTimeout(start, 7000);
      }
      // connection.start()
      // },
      // )
      setTimeout(() => {
        if (this.getIsLoggedIn == false) {
          this.isLogged = false
          // this.login.email = ''
          // this.login.password = ''
          setTimeout(() => { this.$v.$reset() }, 0)
          this.$nextTick(() => { this.$v.$reset() })
        }
      }, 1000)

      this.loading = !false
      setTimeout(() => {
        this.loading = !true
      }, 1000)
    },
    async changePass(obj) {
      await this.changePasswordForUser({
        object: obj,
        successCallback: () => {
          this.$router.push('/');
        },
      })
    },
    openModal() {
      this.$bvModal.show('modal-forgot-password');
    },
    showL() {
      this.show = true;
    },
    showR() {
      this.show = false;
    },
  },
}
</script>

<style scoped lang="scss">
.fa{
  color: $base-color;
}
.error{
  display: flex;
  align-items: flex-end;
}

.row{
  background-image: url('../assets/images/Backgroundpattern.svg');
  height: 500px;
  background-size: contain;
}

.buttonSubmit{
  height: 44px;
}
input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

      .is-valid{
        border-color: lightgray;
        box-shadow: none;
      }

@media screen and (max-width: 1400px) {
  .logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  img{
    width: 500px;
  }
}
@media screen and (max-width: 1190px) {
.container {
  width: 100%;
  margin: 0 !important;
  max-width: inherit !important;
  padding: 0;
  .row {
    margin-right: 0;
    .regiester-wrapper {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
      padding-top: 60px;
      .register-content {
    margin-right: 0px;
    max-width: 500px;
    min-width: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    margin-bottom: 100px;
    .logo {
      img {
        margin-bottom: 0;
        // height: 350px;
      }
    }
      }
      .register-image {
        height: 300px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
   }
  }
}
}
</style>
